.lead-card {
    // width: 75%;
    display: flex;
    // height: 700px;
    justify-content: center;
    // padding: 40px !important;
    padding: 20px;
    padding-inline: 40px;
    // margin-right: 568px;

    .ant-card-body {
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      padding: 0px;
      padding-inline: 0px;
    }
  }
  
  .lead-card-header {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .common-button {
    display: flex;
    justify-content: center;
    margin-block: 20px;
    gap: 10px;
  }
  
  .msg {
    display: flex;
    justify-content: center;
    margin-top: 19px;
  }
  
  .select-applicant-type {
  
    .ant-select:not(.ant-select-customize-input),
    .ant-select-selector {
      height: 40px;
    }
  }
  
  .disabled-view {
    position: absolute;
    inset: 0;
    background: #FFFFFF88;
    z-index: 9;
    max-width: 450px;
    cursor: no-drop;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
  
  .lead-loanType {
    height:485px;
    overflow: auto;
  }

  .lead-loanType-mobileView {
    height:600px;
  }
  
  .lead-loanType::-webkit-scrollbar {
     display: none;
  }

  .custome-radio {
    --appPrimary: #273896;
    svg {
      font-size: 34px;
      path,
      circle {
        stroke:var(--appPrimary);
      }
    }
    .stroke-fill {
      fill: var(--appPrimary);
      path {
        fill: var(--appPrimary);
      }
    }
  }

  .button-form-mobileView {
    width:110px !important;
  }

  .collapse-mobileView {
    .ant-collapse-content-box{
      height: 100% !important;
    }
  }
  