.dashboard-menus {
  --appPrimary: #273896;
  --appSecondary: #8c2e8b;

  margin: 40px 0px;
  display: table;
  width: 100%;

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;

    li {
      background-color: white;
      width: 120px;
      height: 120px;
      margin-bottom: 10px;
      box-shadow: -5px 5px 10px rgb(223 224 230 / 20%),
        5px -5px 10px rgb(223 224 230 / 20%),
        -5px -5px 10px rgb(255 255 255 / 90%),
        5px 5px 13px rgb(246 246 246 / 90%),
        inset 1px 1px 2px rgb(255 255 255 / 30%),
        inset -1px -1px 2px rgb(223 224 230 / 50%);
      border-radius: 10px;
      float: left;
      margin-right: 20px;
      border: 1px solid #E5E8F5;

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px;
        padding-top: 25px;
        position: relative;

        svg {
          font-size: 34px;
          path,
          circle {
            stroke:var(--appPrimary);
          }
        }
        .stroke-fill {
          fill: var(--appPrimary);
          path {
            fill: var(--appPrimary);
          }
        }

        #header-shape-gradient {
          --color-stop: #f12c06;
          --color-bot: #faed34;
        }

        span.count {
          position: absolute;
          right: -5px;
          top: -5px;
          color: white;
          background: var(--appSecondary);
          min-width: 26px;
          padding-inline: 4px;
          height: 26px;
          line-height: 27px;
          font-size: 13px;
          text-align: center;
          border-radius: 30px;
          -webkit-text-fill-color: unset;
        }

        span {
          font-size: 20px;
          //display: block;
          font-size: 14px;

          // background: -webkit-linear-gradient(#042750, #c71c8e);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

.icon {
  margin-left: 10px;
  margin-bottom: 12px;
}

.text {
  margin-left: 10px;
}

@import 'shared/style/Variables.scss';
@import 'shared/style/Mixins.scss';

.custom-carousel {
  .slick-list {
    .slick-track {
      height: 250px;

      .slick-slide {
        img {
          width: 100%;
        }
      }
    }
  }

  .slick-dots {
    bottom: 12px;
    right: 0;
    left: auto;
    margin-right: 4%;

    li {
      width: auto !important;

      button {
        width: 10px;
        height: 10px;
        background: rgba(0, 0, 0, 0.35);
        @include border-radius(10px)
      }
    }

    li.slick-active {
      button {
        background: #C71C8E;
      }
    }
  }
}

.icon {
  margin-left: 10px;
  margin-bottom: 12px;
}

.text {
  margin-left: 10px;
}

.hide-show-button {
  background-color: #C71C8E;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 40px;
}

.graphs-container {
  padding: 16px;

  .graphs-header {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 16px;
  }

  .graphs-title {
    margin: 0;
  }

  .filter-label {
    margin-right: 8px;
  }

  .filter-select {
    padding: 4px 8px;
    border-radius: 4px;
  }

  .ant-row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .ant-col {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ant-card {
    height: 100%;

    .ant-card-body {
      padding: 16px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .chart-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .chart-body {
      flex-grow: 1;
      position: relative;
      height: calc(100% - 30px);
      min-height: 250px;
    }
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #8c8c8c;
  }
}

.summary-card-value{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}